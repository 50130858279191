import * as React from 'react'
import * as PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const Image = styled.img`
  ${css({
    p: [1, null, 2],
    width: '100%',
    height: 'auto',
  })}
`

export function CurrencyProviderImages(props) {
  if (!props.providers) {
    return null
  }

  return (
    <Common.Box
      display="grid"
      gridTemplateColumns={[
        'repeat(2, minmax(150px, 1fr))',
        null,
        null,
        'repeat(4, minmax(150px, 1fr))',
      ]}
      alignItems="center"
      flexWrap="wrap"
      justifyContent="center"
      minHeight="74px"
      width="100%"
      maxWidth="1200px"
      justifyItems="center"
    >
      {props.providers.map(provider => (
        <LazyLoad key={provider} once height="34px" offset={100}>
          <Common.Box
            maxWidth="180px"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Image
              src={provider?.image?.url}
              alt={provider?.name}
              height="24"
              width="115"
            />
          </Common.Box>
        </LazyLoad>
      ))}
    </Common.Box>
  )
}

CurrencyProviderImages.propTypes = {
  providers: PropTypes.array,
}
