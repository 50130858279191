import * as FramerMotion from 'framer-motion'
import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { GameTileAnimation } from './game-tile-animation'
import { GameTileSection } from './game-tile-section'
import { LiveCasinoGameTile } from './live-casino-game-tile'
import { Parallax } from './parallax'
import { mapIndexed } from './map-indexed'
import { useLiveCasinoTablesQuery } from './use-live-casino-tables-query'

const LinkWrapper = styled.div`
  ${css({
    fontSize: [2, 3],
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;
  display: flex;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

export function LiveCasinoGameDisplay() {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()

  const [liveCasinoResponse, filtredGames] = useLiveCasinoTablesQuery(
    'german-landing-page'
  )

  return (
    <Common.Box display="flex" flexDirection="column">
      <Common.Box display="flex" justifyContent="space-between" px={2} pb={1}>
        <Common.Box fontWeight="bold" fontFamily="head" fontSize={5}>
          {i18n.translate('landing-page.live-casino')}
        </Common.Box>
        <ReactRouter.Link to="/live-casino">
          <LinkWrapper>
            {i18n.translate('live-casino-display.show-all')}
          </LinkWrapper>
        </ReactRouter.Link>
      </Common.Box>
      {R.isEmpty(filtredGames) || liveCasinoResponse.fetching ? (
        <Common.Box
          display="flex"
          justifyContent="center"
          alignSelf="center"
          fontSize={['48px', '64px']}
          height="306px"
          pt={3}
        >
          <Icons.Spinner delay />
        </Common.Box>
      ) : (
        <GameTileSection size="medium" layout="grid" columns={4}>
          {() => (
            <FramerMotion.AnimatePresence>
              {mapIndexed(
                (game, index) => (
                  <GameTileAnimation key={`lp-${game.id}`} delay={index * 0.04}>
                    <ReactRouter.Link
                      to={`/casino/games/${game.id}?referrer=${R.drop(
                        1,
                        location.pathname
                      )}`}
                    >
                      <Parallax>
                        <LiveCasinoGameTile game={game} size="small" />
                      </Parallax>
                    </ReactRouter.Link>
                  </GameTileAnimation>
                ),
                R.take(6, filtredGames)
              )}
            </FramerMotion.AnimatePresence>
          )}
        </GameTileSection>
      )}
    </Common.Box>
  )
}
